import Cookies from 'js-cookie'
import generateToken from '../Lib/generateToken'

const updateMeta = (fileInfo, callback, errorCallback) => {
  fetch(`${process.env.REACT_APP_API_URL}media-create`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${Cookies.get('token')}`,
      },
      body: JSON.stringify(fileInfo),
    }).then(res => res.json())
    .then(res => {
      if (res.id) {
        callback(res.id, fileInfo)
      } else {
        errorCallback()
      }
    })
}

const upload = (appConfig, file, callback = () => {}, errorCallback = () => {}) => {

  const extension = file.name.split('.').pop().toLowerCase()
  if (!extension) {
    errorCallback()
    return false
  }

  if (file.size / 1024 / 1024 > 10) {
    alert('Max file size 10MB')
    errorCallback()
    return false
  }

  const fileName = `${generateToken(40)}.${extension}`

  fetch(`${appConfig.media.url}request-upload`, {
    method: 'PUT',
    body: JSON.stringify({
      appName: appConfig.media.name,
      fileName,
      fileType: file.type,
    }),
  })
  .then(response => response.json())
  .then(({ data }) => {
    fetch(data.url, {
      method: 'PUT',
      body: file,
    }).then((d2) => {

      const fileNameParts = fileName.split('.')

      const fileInfo = {
        name: fileName,
        type: file.type,
        size: file.size,
        originalName: file.name,
        extension,
        url: d2.url,
        resizable: data.resizable,
        quality: data.quality,
        sizes: data.sizes.join(','),
        resizedName: `${fileNameParts[0]}-w${data.sizes[0]}-q${data.quality}.${fileNameParts[1]}`
      }

      
      if (['image/jpeg', 'image/png'].includes(fileInfo.type)) {
        const img = new Image() 
        img.src = window.URL.createObjectURL(file)
        img.onload = () => {
          fileInfo.width = img.width
          if (img.width < parseInt(data.sizes[0])) {
            fileInfo.resizedName = `${fileNameParts[0]}-w0-q${data.quality}.${fileNameParts[1]}`
          }
          fileInfo.height = img.height  
          updateMeta(fileInfo, callback, errorCallback)
        }
      } else {
        updateMeta(fileInfo, callback, errorCallback)
      }    
    })
  })
}

export default upload