import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import LanguageSelector from './LanguageSelector'
import MediaViewer from './MediaViewer'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import upload from '../Lib/upload'
import { useConfig } from '../Store'

const File = ({
  storedData,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
  files,
  innerForm,
}) => {
  const appConfig = useConfig()
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [currentValue, setCurrentValue] = useState(value && files[value] ? `${appConfig.media.public}${files[value]}` : false)
  const [dragging, setDragging] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [showMediaView, setShowMediaViewer] = useState(false)

  const updateValue = (v) => {
    if (translatable) {
      value[language] = v
      setValue({ ...value })
    } else {
      setValue(v)
    }
    if (showMediaView) setShowMediaViewer(false)
  }

  const remove = e => {
    e.preventDefault()
    e.stopPropagation()
    if (window.confirm('Confirm?')) {
      updateValue('')
      setCurrentValue(false)
    }
  }

  const onDrop = e => {
    e.stopPropagation()
    e.preventDefault()
    setUploading(true)
    upload(appConfig, e.dataTransfer.files[0], (id, data) => {
      setCurrentValue(`${appConfig.media.public}${data}`)
      updateValue(id)
      setUploading(false)
    }, () => {
      setUploading(false)
    })
  }

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    onChange(name, value, Errors(value, validations, translatable, languages))
  }, [value])

  const input = (
    <div>
      <label>{label}</label>
      <div
        className="relative flex items-stretch items-center bg-white rounded cursor-pointer"
      >
        <div
          onClick={remove}
          className="absolute top-0 right-0 mt-1 mr-2 text-gray hover:text-black"
        >
          ✕
        </div>
        <div className="flex items-center justify-center w-16 h-16 overflow-hidden text-center bg-gray text-xxs">
          {currentValue && <a target="_blank" href={currentValue}>View file</a>}
        </div>
        <div
          onClick={() => setShowMediaViewer(true)}
          onDragEnter={() => setDragging(true)}
          onDragLeave={() => setDragging(false)}
          onDragOver={e => e.preventDefault() && e.stopPropagation()}
          onDrop={e => onDrop(e) && setDragging(false)}
          className={`${
            dragging ? 'border-gray' : ''
          } border ${innerForm ? 'bg-white border-white' : 'bg-grayLight border-grayLight'} rounded flex-1 text-xs py-3 text-gray flex justify-center items-center`}
        >
          <span className="text-center pointer-events-none">
            {uploading ? (
              'Uploading...'
            ) : (
              <span>
                <span>Drag to upload</span>
                <span className="block text-xxs">
                  {currentValue && 'Drag to upload or '} Click to select
                </span>
              </span>
            )}
          </span>
        </div>
      </div>
      {showMediaView && (
        <MediaViewer
          type="file"
          value={currentValue}
          onChange={updateValue}
          onClose={() => setShowMediaViewer(false)}
        />
      )}
    </div>
  )

  return translatable ? (
    <LanguageSelector
      language={language}
      languages={languages}
      onChangeLanguage={v => setLanguage(v)}
    >
      {input}
    </LanguageSelector>
  ) : (
    input
  )
}

export default File
