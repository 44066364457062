import React from 'react'
import { useConfig } from './Store'
import {
  NavLink,
} from 'react-router-dom'

const Dashboard = () => {

  const appConfig = useConfig()

  console.log(appConfig)

  return (
    <div>
      {appConfig && appConfig.menu && 
          <ul className="grid-cols-3 gap-4 md:grid">
            {appConfig.menu.map(item => (
              <li className="p-4 mb-4 bg-white md:border-b md:border-grayLight md:mb-0" key={item.model}>
                <h2 className="block mb-4 wght-semibold">
                  <NavLink className={`border-solid hover:text-primary border-primary hover:wght-semibold`} to={item.path || `/admin/list/${item.model}`}>
                    {item.title}
                  </NavLink>
                </h2>
                <div className="flex">
                  <NavLink className={`border-solid mr-4 hover:text-primary border-primary hover:wght-semibold`} to={item.path || `/admin/list/${item.model}`}>
                    List
                  </NavLink>
                  <NavLink className={`border-solid hover:text-primary border-primary hover:wght-semibold`} to={item.path || `/admin/edit/${item.model}`}>
                    Add
                  </NavLink>
                </div>
              </li>
            ))}
          </ul>
        }
    </div>
  )
}

export default Dashboard