import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'

const Color = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: { label },
  validations,
  languages,
  innerForm,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState('')

  const onChangeText = e => {
    const newCurrentValue = e.target.value.replace('#', '')
    if (translatable) {
      value[language] = `${newCurrentValue}`
      setValue({ ...value })
    } else {
      setValue(`${newCurrentValue}`)
    }
    setCurrentValue(newCurrentValue)
  }

  useEffect(() => {
    setCurrentValue(translatable ? `${value[language] || ''}` : `${value}`)
  }, [language, translatable])

  useEffect(() => {
    if (defaultValue(name, storedData, translatable) === value) return
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const input = (
    <div className="flex bg-white rounded">
      <div className="w-6 border-r rounded-l border-grayLight" style={{ backgroundColor: `#${currentValue}` }}></div>
      <input
        name={name}
        className={`w-full rounded ${
          translatable && Object.keys(languages)[0] === language
            ? 'rounded-tl-none'
            : ''
        } px-2 py-1 ${
          showErrors && errors.length > 0 ? 'border border-error' : ''
        } ${innerForm ? 'bg-white' : 'bg-grayLight'} outline-none placeholder-gray`}
        type="text"
        placeholder={label}
        value={currentValue}
        onChange={onChangeText}
      />
    </div>
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {input}
        </LanguageSelector>
      ) : (
        input
      )}
    </div>
  )
}

export default Color
