import React, { useState, useEffect } from 'react'
import Cookies from 'js-cookie'
import sha1 from 'js-sha1'
import {
  useHistory,
  Link
} from 'react-router-dom'

import Text from './Form/Text'
import Password from './Form/Password'
import Container from './Container'
import flatten from './Lib/flatten'

const Reset = () => {
  const history = useHistory()
  const [data, setData] = useState({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [loading, setLoading] = useState(false)

  const updateData = (key, value, errs = []) => {
    data[key] = value
    setData({ ...data })
    errors[key] = errs
    setErrors({ ...errors })
  }

  const send = () => {
    setErrorMessage(false)
    const errs = flatten(errors)
    if (Object.keys(data).length === 0) {
      setShowErrors(true)
      setErrorMessage('The password field is empty')
      return
    }
    if (Object.keys(errs).length > 0) {
      setShowErrors(true)
      setErrorMessage(Object.values(errs)[0])
    } else {
      setShowErrors(false)
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}session-reset-password`, {
        method: 'POST',
        body: JSON.stringify({
          password: data.password,
          token: data.token
        })
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          setLoading(false)
          if (response.status === 200) {
            history.replace(`/result`)
          } else {
            setErrorMessage(response.body.error)
          }
        })
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2">
        <div>
          <h2 className="mb-4 text-xl text-center wght-semibold">We have emailed you a recovery code.</h2>
          <div>
            <div className="mb-4">
              <Text 
                storedData={{}}
                showErrors={showErrors}
                onChange={updateData}
                name="token"
                translatable={false}
                options={{ label: 'Recovery code' }}
                validations={{ not_blank: 'Not blank' }}
              />
            </div>
            <div className="mb-4">
              <Password
                storedData={{}}
                showErrors={showErrors}
                onChange={updateData}
                name="password"
                translatable={false}
                options={{ label: 'New Password' }}
                validations={{ not_blank: 'Not blank', password: 'The password does not match.', min_length: 'Password must be at least 6 characters.' }}
                hideRepeat={false}
              />
            </div>
            {errorMessage && <span className="block my-4 text-error">{errorMessage}</span>}
          </div>
          <div className="flex items-end justify-center">
            {loading
              ? <span className="wght-bold">Loading...</span>
              : <span onClick={send} className="cursor-pointer wght-bold hover:wght-semibold hover:text-primary">Send &#8599;</span>
            }
          </div>
        </div>
      </div>
      <div className="flex items-end mt-2 mb-8">
        <Link
            to={`/login`}
            className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
          >Cancel
        </Link>
      </div>
    </div>
  )
}

export default Reset