import React, { useState } from 'react'
import Cookies from 'js-cookie'
import sha1 from 'js-sha1'
import {
  useHistory,
  Link,
} from 'react-router-dom'

import Text from './Form/Text'
import Password from './Form/Password'
import Container from './Container'
import flatten from './Lib/flatten'

const Login = () => {
  const history = useHistory()
  const [data, setData] = useState({})
  const [errorMessage, setErrorMessage] = useState(false)
  const [errors, setErrors] = useState({})
  const [showErrors, setShowErrors] = useState(false)
  const [loading, setLoading] = useState(false)

  const updateData = (key, value, errs = []) => {
    data[key] = value
    setData({ ...data })
    errors[key] = errs
    setErrors({ ...errors })
  }
  
  const login = () => {
    setErrorMessage(false)
    const errs = flatten(errors)
    if (Object.keys(errs).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}session-login`, {
        method: 'POST',
        body: JSON.stringify({
          username: data.username,
          password: sha1(data.password),
        }),
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          setLoading(false)
          if (response.status === 200) {
            Cookies.set('user', JSON.stringify(response.body.user))
            Cookies.set('token', response.body.token)
            history.replace(`/admin/`)
          } else {
            setErrorMessage(response.body.error)
          }
        })
    }
  }

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="p-4 bg-white md:w-1/2 lg:w-1/4">
        <h2 className="mb-4 text-xl text-center wght-semibold">Login</h2>
        <div>
          <div className="mb-4">
            <Text
              storedData={{}}
              showErrors={showErrors}
              onChange={updateData}
              name="username"
              translatable={false}
              options={{ label: 'Username' }}
              validations={{ not_blank: 'Not blank' }}
            />
          </div>
          <Password
            storedData={{}}
            showErrors={showErrors}
            onChange={updateData}
            name="password"
            translatable={false}
            options={{ label: 'Password' }}
            validations={{ not_blank: 'Not blank' }}
            hideRepeat={true}
            onKeyPress={e => (e.key === 'Enter' && login())}
          />
          {errorMessage && <span className="block mt-4 text-error">{errorMessage}</span>}
        </div>
        <div className="flex items-end justify-center mt-4">
          {loading
            ? <span className="wght-bold">Loading...</span>
            : <span onClick={login} className="cursor-pointer wght-bold hover:wght-semibold hover:text-primary">Login &#8599;</span>
          }
        </div>
      </div>
      <div className="flex items-end mt-4">
        <Link
          to={`/recovery`}
          className="text-xs cursor-pointer hover:wght-semibold hover:text-primary"
          >Forgot your password?
        </Link>
      </div>
    </div>
  )
}

export default Login