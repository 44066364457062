import React, { createContext, useContext } from 'react'

export const ConfigContext = createContext()

export const GlobalConfig = ({
  children,
  config
}) => <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>

export const useConfig = () => {
  const ctx = useContext(ConfigContext) || []
  return ctx
}
